const ENV_VAR = {
    //生产
    production:
    {
        // 北京机床 pro api
        api_url: "https://service.cmtba.org.cn/api",
    },
    //开发
    development:
    {
        // 北京机床 dev api
        api_url: "https://service.cmtba.org.cn/api",
    },
}
export default ENV_VAR;