import axios from 'axios';
import Vue from 'vue';
import Qs from "qs";
// import Store from "./store";
import HttpStatus from 'http-status';
import { MessageBox } from "element-ui";
import ENV_VAR from '../config/envConfig';


let instance = axios.create({
    baseURL: ENV_VAR[process.env.NODE_ENV] !== undefined ? ENV_VAR[process.env.NODE_ENV]["api_url"] : ENV_VAR['development']["api_url"]
});

// 添加请求拦截器
instance.interceptors.request.use(
    config => {
        // 判断token是否存在，存在则将每个请求页面header都添加token
        if (sessionStorage.token) {
            config.headers.common["Authorization"] = `${sessionStorage.token_type} ${sessionStorage.token
                }`;
            let locDate = new Date();
            const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
            const getOutTime = new Date(userinfo.outTime).getTime();
            if (locDate.getTime() >= getOutTime) {

                MessageBox({
                    showClose: false,
                    closeOnClickModal: false,
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    offset: 250,
                    message: `<span style="font-size:20px;">登录超时，请重新登录!</span>`,
                    title: "提示",
                    confirmButtonText: "确定"
                }).then(action => {
                    sessionStorage.clear();
                    //跳转登录页面
                    window.location.href = "http://" + window.location.host + "/#/";
                });
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use((response) => {
    if (response && response.status === 200) {
        return response;
    };
}, (error) => {
    // if (error.message.indexOf('Network Error') >= 0) {
    //   //
    //   sessionStorage.clear();
    //   //
    //   Vue.prototype.$notify.error('Network error, please check your network settings!')
    // }
    // else 
    if (error.response.data.error === "invalid_err") //API验证错误
    {
        // Vue.prototype.$notify.error(error.response.data.message)
        return Promise.reject(error);
    } else if (error.response.status === HttpStatus.UNAUTHORIZED) { //401 token错误
        sessionStorage.clear();
        Router?.replace({
            path: "/",
            query: { redirect: router.currentRoute.fullPath }
        });
    } else if (error.response.status >= HttpStatus.BAD_REQUEST) {
        Vue.prototype.$notify.error(error.response.data.message);
    }
});

// 查询
const glApi_Applet_Q = params => {
    return instance.post("cmtbashow_applet_q", params).then(res => res.data);
};

export {
    glApi_Applet_Q
};