import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "swiper/swiper.min.css"

// API相关
import {
  glApi_Applet_Q,
} from './utils/axios'

Vue.config.productionTip = false

//
Vue.prototype.$glApi_Applet_Q = glApi_Applet_Q;
//

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
